//export const ADMIN = ['barbara', 'matt', 'jeff', 'minjoo', 'jenna', 'manu', 'dave', 'tommi', 'aditya', 'mark', 'pat', 'suchitra', 'nithin', 'piyush'];
//https://mkyong.com/java8/java-display-all-zoneid-and-its-utc-offset/ - TIMEZONES

export const WAREHOUSE = {
  'default' : { //ichikawa test 23.09m wide 10.73 height
    background_src: 'Locix_Office_Furniture.png', //1055 x 492
    big: {
      width: 1055,
      height: 492,
      pixels_per_meter: 45.7
    },
    small: {
      width: 1055,
      height: 492,
      pixels_per_meter: 45.7
    },
    group_type: [],
    tracker_type: ['phone', 'tdoa_tracker', 'worker'],
    origin: {x: 0, y: 0}, //in meters

    map_order: ["1"],
    maps: {
      "1" : {
        background_src: 'Default25x25.png',
        small: {
          width: 1055,
          height: 1055,
          pixels_per_meter: 42.2
        },
        origin: {x: 0, y: 0}, //in meters
      }
    },
  },
  '2a9642e57d38453bb401d2571825af1d' : { //Ichikawa Japan
    background_src: 'Ichikawa_2f_New.png',
    small: {
      width: 1122,
      height:  656,
      pixels_per_meter: 7.06,
    },
    origin: {x: 53.1, y: .2}, //in meters
    legend_src: 'Ichikawa_legend.png',
    group_type: ['Floor 1', 'Floor 2'],
    tracker_type: ['forklift', 'worker'],

    map_order: ["2", "1"],
    maps: {
      "2" : {
        background_src: 'Ichikawa_2f_New.png', //old - 2F_Full.png
        small: {
          width: 1122,
          height:  656,
          pixels_per_meter: 7.06,
        },
        origin: {x: 53.1, y: .2}, //in meters
      },
      "1" : {
        background_src: 'Ichikawa1F.png',
        small: {
          width: 1113,
          height:  658,
          pixels_per_meter: 7.06,
        },
        origin: {x: 53.1, y: .2}, //in meters
      }
    },
    mscs: {
      default_floor: "2",
      size1: { //laptop 1280x800
        live: {
          "2" : {
            background_src: 'Ichikawa_2f_New.png',
            small: {
              width: 530,
              height:  310,
              pixels_per_meter: 3.33,
            },
            origin: {x: 53.1, y: .2}, //in meters
          },
          "1" : {
            background_src: 'Ichikawa1F.png',
            small: {
              width: 1113,
              height:  658,
              pixels_per_meter: 7.06,
            },
            origin: {x: 53.1, y: .2}, //in meters
          }
        },
        rack: {
          "2" : {
            background_src: 'Ichikawa_2f_New.png',
            small: {
              width: 250,
              height:  146,
              pixels_per_meter: 1.57,
            },
            origin: {x: 53.1, y: .2}, //in meters
          },
          "1" : {
            background_src: 'Ichikawa1F.png',
            small: {
              width: 1113,
              height:  658,
              pixels_per_meter: 7.06,
            },
            origin: {x: 53.1, y: .2}, //in meters
          }
        },
        top: {
          rowHeight: 27,
          tableWidth: 250,
          columnWidth: 59,
        }
      },
      size2: { //laptop 1400x800
        live: {
          "2" : {
            background_src: 'Ichikawa_2f_New.png',
            small: {
              width: 620,
              height:  363,
              pixels_per_meter: 3.9,
            },
            origin: {x: 53.1, y: .2}, //in meters
          },
          "1" : {
            background_src: 'Ichikawa1F.png',
            small: {
              width: 1113,
              height:  658,
              pixels_per_meter: 7.06,
            },
            origin: {x: 53.1, y: .2}, //in meters
          }
        },
        rack: {
          "2" : {
            background_src: 'Ichikawa_2f_New.png',
            small: {
              width: 290,
              height:  169,
              pixels_per_meter: 1.82,
            },
            origin: {x: 53.1, y: .2}, //in meters
          },
          "1" : {
            background_src: 'Ichikawa1F.png',
            small: {
              width: 1113,
              height:  658,
              pixels_per_meter: 7.06,
            },
            origin: {x: 53.1, y: .2}, //in meters
          }
        },
        top: {
          rowHeight: 40,
          tableWidth: 300,
          columnWidth: 73,
        }
      },
      size3: { //monitor 1920 x 1080
        live: {
          "2" : {
            background_src: 'Ichikawa_2f_New.png',
            small: {
              width: 855,
              height:  500,
              pixels_per_meter: 5.38,
            },
            origin: {x: 53.1, y: .2}, //in meters
          },
          "1" : {
            background_src: 'Ichikawa1F.png',
            small: {
              width: 1113,
              height:  658,
              pixels_per_meter: 7.06,
            },
            origin: {x: 53.1, y: .2}, //in meters
          }
        },
        rack: {
          "2" : {
            background_src: 'Ichikawa_2f_New.png',
            small: {
              width: 355,
              height:  207,
              pixels_per_meter: 2.23,
            },
            origin: {x: 53.1, y: .2}, //in meters
          },
          "1" : {
            background_src: 'Ichikawa1F.png',
            small: {
              width: 1113,
              height:  658,
              pixels_per_meter: 7.06,
            },
            origin: {x: 53.1, y: .2}, //in meters
          }
        },
        top: {
          rowHeight: 40,
          tableWidth: 375,
          columnWidth: 89,
        }
      }
    },
    wms: true,
  },
  'bf7d3aba38dd4f54bdc493b9d89539ed' : { //ichikawa test 23.09m wide 10.73 height
    background_src: 'Locix_Office_Furniture.png', //1055 x 492
    big: {
      width: 1055,
      height: 492,
      pixels_per_meter: 45.7
    },
    small: {
      width: 1055,
      height: 492,
      pixels_per_meter: 45.7
    },
    group_type: [],
    tracker_type: ['worker'],
    origin: {x: 0, y: 0}, //in meters

    map_order: ["1", "2"],
    maps: {
      "1" : {
        background_src: 'Locix_Office_Furniture.png',
        small: {
          width: 1055,
          height: 492,
          pixels_per_meter: 45.7
        },
        origin: {x: 0, y: 0}, //in meters
      },
      "2" : {
        background_src: 'Locix_Office_Furniture.png',
        small: {
          width: 1055,
          height: 492,
          pixels_per_meter: 45.7
        },
        origin: {x: 0, y: 0}, //in meters
      },
    },
  },
  'e2983e19bf8a4b349f430e883b9f2352' : { //Proplogis LPS: 214.87 feet = 65.49 meters,  width -  59.17 feet = 18.03 meters height
    background_src: 'Prologis.png',
    big: {
      width: 1055,
      height: 300,
      pixels_per_meter: 16.066,
    },
    small: {
      width: 1055,
      height: 300,
      pixels_per_meter: 16.066,
    },
    group_type: [],
    tracker_type: ['forklift', 'worker'],
    origin: {x: 65.3554089381, y: 0.4357027263}, //in meters

    map_order: ["1"],
    maps: {
      "1" : {
        background_src: 'Prologis.png',
        small: {
          width: 1055,
          height: 300,
          pixels_per_meter: 16.066,
        },
        origin: {x: 65.3554089381, y: 0.4357027263}, //in meters
      }
    },
  },
  '9d5d9e9f973048118c81f2ac0c5fa838' : { //daiken japan
    background_src: 'DaikinTIC-2d.png',
    big: {
      width: 1360,
      height: 430,
      pixels_per_meter: 23.08,
    },
    small: {
      width: 1000,
      height: 316,
      pixels_per_meter: 16.97,
    },
    group_type: [],
    tracker_type: ['forklift', 'worker'],
    origin: {x: 0, y: 0}, //in meters

    map_order: ["1"],
    maps: {
      "1" : {
        background_src: 'DaikinTIC-2d.png',
        small: {
          width: 1000,
          height: 316,
          pixels_per_meter: 16.97,
        },
        origin: {x: 0, y: 0}, //in meters
      }
    },
  },
  '2f007bf9c94d4e6c99d0aae751de351f' : {
    background_src: 'YEDHQ-GUIbackground-S1.png',
    big: {
      width: 615,
      height: 725,
      pixels_per_meter: 48.61,
    },
    small: {
      width: 615,
      height: 725,
      pixels_per_meter: 48.61,
    },
    group_type: [],
    tracker_type: ['worker'],
    origin: {x: .48, y: .49}, //in meters
    map_order: ["1"],
    maps: {
      "1" : {
        background_src: 'YEDHQ-GUIbackground-S1.png',
        small: {
          width: 615,
          height: 725,
          pixels_per_meter: 48.61,
        },
        origin: {x: .48, y: .49}, //in meters
      }
    },
  },
  'df0389eb7fb647f2921077b69288ded5' : { //K & N POC
    background_src: 'KuehneNagelSingapore.png',
    big: {
      width: 1055,
      height: 976,
      pixels_per_meter: 11.75,
    },
    small: {
      width: 1055,
      height:  976,
      pixels_per_meter: 11.75,
    },
    group_type: [],
    tracker_type: ['forklift', 'worker'],
    origin: {x: 0.2627737226 , y: 0.08759124088}, //in meters

    map_order: ["1"],
    maps: {
      "1" : {
        background_src: 'KuehneNagelSingapore.png',
        small: {
          width: 1055,
          height:  976,
          pixels_per_meter: 11.75,
        },
        origin: {x: 0.2627737226 , y: 0.08759124088}, //in meters
      }
    },
  },
 /*'dd6ebee548c941a0a15b070f2c5826d5' : { //MSCS Malaysia
   background_src: 'Bangi.png',
   big: {
     width: 1055,
     height: 648,
     pixels_per_meter: 7.925,
   },
   small: {
     width: 1055,
     height:  648,
     pixels_per_meter: 7.925,
   },
   group_type: [],
   tracker_type: ['forklift', 'worker'],
   origin: {x: 4.3, y: 41.2}, //in meters
   z: {zone_min: 7.6, anchor_min: 11, tracker_min: 11.17, y_offset: -135}, //feet - need to use feet b/c x,y is in feet, y_offset is relative position in meters

   map_order: ["1"],
   maps: {
     "1" : {
       background_src: 'Bangi.png',
       small: {
         width: 1055,
         height: 648,
         pixels_per_meter: 7.925,
       },
       origin: {x: 4.3, y: 41.2}, //in meters
       //z: {zone_min: 7.6, anchor_min: 11, tracker_min: 11.17, y_offset: -135},
       z: {zone_min: 7.6, anchor_min: 11, tracker_min: 11, y_offset: -135},
     }
   },
 },*/
 'dd6ebee548c941a0a15b070f2c5826d5' : { //MSCS Malaysia
    background_src: 'BangiExpansion_Mezztop.png',
    big: {
      width: 1055,
      height: 520,
      pixels_per_meter:  3.9,
    },
    small: {
      width: 1055,
      height: 520,
      pixels_per_meter: 3.9,
    },
    group_type: [],
    tracker_type: ['forklift', 'worker'],
    origin: {x: 104.87179, y: 46.66667}, //in meters
    z: {zone_min: 7.6, anchor_min: 11, tracker_min: 11.17, y_offset: 225}, //feet - need to use feet b/c x,y is in feet, y_offset is relative position in meters

    map_order: ["1"],
    maps: {
      "1" : {
        background_src: 'BangiExpansion_Mezztop.png',
        small: {
          width: 1055,
          height: 520,
          pixels_per_meter: 3.9,
        },
        origin: {x: 104.87179, y: 46.66667}, //in meters
        //z: {zone_min: 7.6, anchor_min: 11, tracker_min: 11.17, y_offset: -135},
        z: {zone_min: 7.6, anchor_min: 20, tracker_min: 11, y_offset: 225},
      }
    },
  },
  'ed3f28af18564fada8dff20e4ee15631' : { //Mist
    background_src: 'mist_demo2.png',
    big: {
      width: 815,
      height: 725,
      pixels_per_meter: 48.84,
    },
    small: {
      width: 815,
      height: 725,
      pixels_per_meter: 48.84,
    },
    group_type: [],
    tracker_type: ['worker'],
    origin: {x: .164, y: .369}, //in meters

    map_order: ["1"],
    maps: {
      "1" : {
        background_src: 'mist_demo2.png',
        small: {
          width: 815,
          height: 725,
          pixels_per_meter: 48.84,
        },
        origin: {x: .164, y: .369}, //in meters
      }
    },
  },
  'b5fcb9a07a684160b750e07bd5b36eef' : {
    background_src: 'Uniqlomap.png',
    big: {
      width: 883,
      height: 735,
      pixels_per_meter: 11.92,
    },
    small: {
      width: 883,
      height: 735,
      pixels_per_meter: 11.92,
    },
    group_type: ['Floor 2'],
    tracker_type: ['worker'],
    origin: {x: 4.6977, y: .25167}, //in meters

    map_order: ["1"],
    maps: {
      "1" : {
        background_src: 'Uniqlomap.png',
        small: {
          width: 883,
          height: 735,
          pixels_per_meter: 11.92,
        },
        origin: {x: 4.6977, y: .25167}, //in meters
      }
    },
    wms: true,
  },
  '51fe12cd8d1546279d82230c6da07f0d' : { //Daikin - Point Zero
    background_src: 'Daikin_ZeroPoint.png',
    big: {
      width: 625,
      height: 725,
      pixels_per_meter: 20.0719,
    },
    small: {
      width: 625,
      height: 725,
      pixels_per_meter: 20.0719,
    },
    group_type: ['DK', 'P0', 'OKM', 'PA', 'MC', 'MEC', 'TOA', 'SMZ', 'IS', 'MS', 'KJM', 'SB'],
    tracker_type: ['worker'],
    origin: {x: .2491, y: .2491}, //in meters

    map_order: ["1"],
    maps: {
      "1" : {
        background_src: 'Daikin_ZeroPoint.png',
        small: {
          width: 827,
          height: 1072,
          pixels_per_meter: 28.37837,
        },
        origin: {x: 0.987, y: 0.599}, //in meters
      }
    },
  },
  '3988d5b4a50f4c0f9c5bc53dd80e4ea1' : { //Daikin DSV
    background_src: 'DSV2D.jpg',
    big: {
      width: 681,
      height: 839,
      pixels_per_meter: 33.4645,
    },
    small: {
      width: 681,
      height: 839,
      pixels_per_meter: 33.4645,
    },
    group_type: [],
    tracker_type: ['worker'],
    origin: {x: 1.165 , y: 1.105}, //in meters

    map_order: ["1"],
    maps: {
      "1" : {
        background_src: 'DSV2D.jpg',
        small: {
          width: 681,
          height: 839,
          pixels_per_meter: 33.4645,
        },
        origin: {x: 1.165 , y: 1.105}, //in meters
      }
    },
  },
  '372860a9fd6b48d88f1dedacacfcacb1' : { //Daikin DSV TDOA
    group_type: [],
    tracker_type: ['phone'],
    map_order: ["1"],
    maps: {
      "1" : {
        background_src: 'DSV2D_tdoa.jpg',
        small: {
          width: 681,
          height: 839,
          pixels_per_meter: 33.4645,
        },
        origin: {x: 1.165 , y: 1.105}, //in meters
      }
    },
  },
  'd954674dabd7411e812759b0edf0a4e5' : { //ABInBevTulsa
    background_src: 'Tulsa.png',
    big: {
      width: 1055,
      height: 797,
      pixels_per_meter: 9.870587707,
    },
    small: {
      width: 1055,
      height: 797,
      pixels_per_meter: 9.870587707,
    },
    group_type: [],
    tracker_type: ['forklift', 'worker'],
    origin: {x: .2 , y: .2}, //in meters

    map_order: ["1"],
    maps: {
      "1" : {
        background_src: 'Tulsa.png',
        small: {
          width: 1055,
          height: 797,
          pixels_per_meter: 9.870587707,
        },
        origin: {x: .2, y: .2}, //in meters
      }
    },
    wms: true,
    wms_time_zone: 'America/Chicago',
  },
  'f064e086e6574b8cb7da0831cb40e05b' : { //BML
    background_src: 'BML.png',
    big: {
      width: 844,
      height: 379,
      pixels_per_meter: 18.6041,
    },
    small: {
      width: 844,
      height: 379,
      pixels_per_meter: 18.6041,
    },
    group_type: ['Regular', 'Spot'],
    tracker_type: ['worker'],
    group_tracker_select: {
     'Regular_worker' : true,
     'Spot_worker': false,
    },

    origin: {x: 1.129, y: 0.806}, //in meters

    map_order: ["1"],
    maps: {
      "1" : {
        background_src: 'BML.png',
        small: {
          width: 844,
          height: 379,
          pixels_per_meter: 18.6041,
        },
          origin: {x: 1.129, y: 0.806}, //in meters
      }
    },
    wms: true,
  },
  '7dc5005560e0477f93c5a5e0e0dd7221' : { //SCSK
    background_src: 'SCSK.png',
    big: {
      width: 339,
      height: 555,
      pixels_per_meter: 30.0562,
    },
    small: {
      width: 339,
      height: 555,
      pixels_per_meter: 30.0562,
    },
    group_type: [],
    tracker_type: ['worker'],
    origin: {x: 0.3327, y: 0.3992}, //in meters

    map_order: ["1"],
    maps: {
      "1" : {
        background_src: 'SCSK.png',
        small: {
          width: 339,
          height: 555,
          pixels_per_meter: 30.0562,
        },
        origin: {x: 0.3327, y: 0.3992}, //in meters
      }
    },
  },
  '05c2da1ffe9247bc96585321c5146d36' : { //Gu Kobe/Fast Retailing
    group_type: ["Floor 4"],
    tracker_type: ['worker'],
    map_order: ["4"],
    maps: {
      "4" : {
        background_src: 'GUKobe_1.png',
        small: {
          width: 371,
          height: 726,
          pixels_per_meter: 7.55981,
        },
        origin: {x: .3968, y: .6614}, //in meters
      }
    },
    wms: true,
  },
  '96cc946ca4ba419590dd3e5767e3e76b' : { //Kraft Heinz 47.61m x 143m
    group_type: [],
    tracker_type: ['forklift'],
    map_order: ["1"],
    maps: {
      "1" : {
        background_src: 'KHA-POC.png',
        small: {
          width: 416,
          height: 1250,
          pixels_per_meter: 8.7364,
        },
        //origin: {x: -183.095, y: 6.314}, //in meters from Pat
        origin: {x: -198.3, y: .5381}
      }
    },
    wms: true,
    wms_time_zone: 'America/Chicago',
  },
  'da9972bc80e64945bfc96076b1e84edc' : {
    group_type: [],
    tracker_type: ['phone', 'tdoa_tracker', 'worker'],
    origin: {x: 0, y: 0}, //in meters*/
    map_order: ["4", "1"],
    maps: {
      "4" : {
        background_src: 'Locix_Office_Furniture.png', //1055 x 492
        small: {
          width: 1055,
          height: 492,
          pixels_per_meter: 45.7
        },
        origin: {x: 0, y: 0}, //in meters*/
      },
      "1" : {
        background_src: 'Locix_Office_Furniture.png', //1055 x 492
        small: {
          width: 1055,
          height: 492,
          pixels_per_meter: 45.7
        },
        origin: {x: 0, y: 0}, //in meters*/
      }
    },
  },
  '481fd6485d4c499ca97b25016fa9800b' : { //Nippon Express
    group_type: [],
    tracker_type: ['worker'],
    map_order: ["1"],
    maps: {
      "1" : {
        background_src: 'NEUPhillipsburgPOCareaDBWfeet_px18_741_0.6906077.png',
        small: {
          width: 513,
          height: 697,
          pixels_per_meter: 4.263,
        },
        //(x,y) = (18,23), with (0,0) toward bottom left
        origin: {x: 7.5059608999,  y: 6.33315450929} //meter
      }
    },
    wms: true,
    wms_time_zone: 'America/New_York',
  },
  '88748292ee864d7490bc3e9409de4c06' : { //JR Central
    group_type: [],
    tracker_type: ['worker'],
    map_order: ["1"],
    maps: {
      "1" : {
        background_src: 'JRCentralNagoyaStationPOCFeet_px215_389_0.1447429.png',
        small: {
          width: 791,
          height: 570,
          pixels_per_meter:  22.6667,
        },
        origin: {x: 9.4852, y: 7.9852} //meter
      }
    },
  },
  '5a53420f231f46a9aa87bf8f5027896c' : { /* LPS Proplogis LPS: 214.87 feet = 65.49 meters,  width -  59.17 feet = 18.03 meters height */
    background_src: 'Prologis.png',
    big: {
      width: 1055,
      height: 300,
      pixels_per_meter: 16.066,
    },
    small: {
      width: 1055,
      height: 300,
      pixels_per_meter: 16.066,
    },
    group_type: [],
    tracker_type: ['forklift', 'worker'],
    origin: {x: 65.3554089381, y: 0.4357027263}, //in meters

    map_order: ["1"],
    maps: {
      "1" : {
        background_src: 'Prologis.png',
        small: {
          width: 1055,
          height: 300,
          pixels_per_meter: 16.066,
        },
        origin: {x: 65.3554089381, y: 0.4357027263}, //in meters
      }
    },
  },
  'f2d6f15e039f495a84e3ed54383670fb' : { //LPS Belmont, 54.6 meter in x, 30.73 meter in y
    group_type: [],
    tracker_type: ['forklift', 'worker'],
    map_order: ["1"],
    maps: {
      "1" : {
        background_src: 'BelmontTrain.png', //'Belmont2.png',
        small: {
          width: 1055,
          height: 592, //594
          pixels_per_meter: 19.2, //19.32,
        },
        origin: {x: 0, y: 0} //meter
      }
    },
  },
  '19d2875b79b64b12b28a80fd287e482e' : { //LPS Michigan,  10m x 38m
    group_type: [],
    tracker_type: ['forklift', 'worker'],
    map_order: ["1"],
    maps: {
      "1" : {
        background_src: 'Michigan3.png',
        small: {
          width: 208,
          height: 791,
          pixels_per_meter: 20.8
        },
        origin: {x: 10, y: 0} //meter
      }
    },
  },
  '70636258d8cd4266be0ef36b1479e175' : { //Wifi Belmont no lobby -  27.617928m x 9.87m
                                         //Wifi Belmont 2 w lobby and extended room - 32.372808 x 10.617m
    group_type: [],
    tracker_type: ['phone', 'tdoa_tracker', 'worker'],
    map_order: ["1"],
    maps: {
      "1" : {
        background_src: 'ZainarWifiNew3.png', //new with smaller table - 2, 3 with lobby table.
        small: {
          width: 1055,
          height: 346,
          pixels_per_meter: 32.9,
        },
        origin: {x: 0, y: 1.75}, //in meters
      },
    },
  },
  'e1a6239c7b7d44b7b0ec1c0f347a9338' : { //Japan Demo 30mx50m
    group_type: [],
    tracker_type: ['worker'],

    map_order: ["1"],
    maps: {
      "1" : {
        background_src: 'FieldTest.png',
        small: {
          width: 525,
          height: 875,
          pixels_per_meter: 17.5,
        },
        origin: {x: 0, y: 0}, //in meters*/
      }
    },
  },
  '81d8d95279dd49799676d5c2b20c481b' : {
    group_type: [],
    tracker_type: ['phone', 'worker'],

    map_order: ["1"],
    maps: {
      "1" : {
        background_src: 'ZainarWifiNew3.png',
        small: {
          width: 1055,
          height: 346,
          pixels_per_meter: 32.9,
        },
        origin: {x: 0, y: 1.75},
        gt:{
          'gt1': {x: 6.096, y: 5.60832, z: 1.524},
          'gt2': {x: 10.0584, y: 5.60832, z: 1.524},
          'gt3': {x: 13.4112, y: 5.60832, z: 1.524},
          'gt4': {x: 16.4592, y: 5.60832, z: 1.524},
          'gt5': {x: 20.1168, y: 5.60832, z: 1.524},
          'gt6': {x: 5.1816, y: 3.048, z: 1.524},
          'gt7': {x: 8.8392, y: 1.524, z: 1.524},
          'gt8': {x: 11.5824, y: 1.524, z: 1.524},
          'gt9': {x: 14.3256, y: 7.1628, z: 1.524},
          'gt10': {x: 15.24, y: 1.524, z: 1.524},
          'gt11': {x: 17.6784, y: 1.524, z: 1.524},
          'gt12': {x: 20.90928, y: 2.1336, z: 1.524},
        },
      },
    },
  },
  '7133dbb1bc3d43d79ef5e720e577f8aa' : { //Belmont Dev Test Warehouse - short map
    group_type: [],
    tracker_type: ['forklift', 'worker'],
    map_order: ["1"],
    maps: {
      "1" : {
        background_src: 'BelmontOffice3.png',
        small: {
          width: 1055,
          height: 592,
          pixels_per_meter: 19.2,
        },
        origin: {x: 0, y: 0},
        gt: {
          'gt1': {x: 41, y: 15, z: 1.524},
          'gt2': {x: 39, y: 16, z: 1.524},
          'gt3': {x: 38, y: 18, z: 1.524},
          'gt4': {x: 39, y: 20, z: 1.524},
          'gt5': {x: 41, y: 21, z: 1.524},
          'gt6': {x: 43, y: 20, z: 1.524},
          'gt7': {x: 44, y: 18, z: 1.524},
          'gt8': {x: 43, y: 16, z: 1.524},
          'gt11': {x: 30.0, y: 28.5, z: 1.6},
          'gt12': {x: 32.0, y: 28.5, z: 1.6},
          'gt13': {x: 37.0, y: 28.5, z: 1.6},
          'gt14': {x: 42.0, y: 28.5, z: 1.6},
          'gt15': {x: 47.0, y: 28.5, z: 1.6},
          'gt16': {x: 52.0, y: 28.5, z: 1.6},
          'gt21': {x: 33.0, y: 22.0, z: 1.6},
          'gt22': {x: 36.0, y: 22.0, z: 1.6},
          'gt23': {x: 40.0, y: 22.0, z: 1.6},
          'gt24': {x: 44.0, y: 22.0, z: 1.6},
          'gt25': {x: 48.0, y: 22.0, z: 1.6},
          'gt26': {x: 52.0, y: 22.0, z: 1.6},
          'gt61': {x: 34.0, y: 11.0, z: 1.6},
          'gt62': {x: 38.0, y: 12.0, z: 1.6},
          'gt63': {x: 42.0, y: 11.0, z: 1.6},
          'gt64': {x: 45.0, y: 12.0, z: 1.6},
          'gt65': {x: 49.0, y: 11.0, z: 1.6},
          'gt66': {x: 52.0, y: 12.0, z: 1.6},
          'gt71': {x: 41.0, y: 20.0, z: 1.6},
          'gt72': {x: 40.0, y: 16.0, z: 1.6},
          'gt73': {x: 43.0, y: 18.0, z: 1.6},
          'gt30': {x: 46.0, y: 19.0, z: 1.6},
          'gt31': {x: 44.5, y: 19.0, z: 1.6},
          'gt32': {x: 37.5, y: 19.0, z: 1.6},
          'gt33': {x: 37.0, y: 19.0, z: 1.6},
          'gt40': {x: 46.0, y: 14.5, z: 1.6},
          'gt41': {x: 44.0, y: 14.5, z: 1.6},
          'gt42': {x: 42.0, y: 14.5, z: 1.6},
          'gt43': {x: 40.0, y: 14.5, z: 1.6},
          'gt44': {x: 38.0, y: 14.5, z: 1.6},
          'gt45': {x: 36.0, y: 14.5, z: 1.6},
        }
      }
    },
  },
  '3f9b372a68334ccfa28015bb2025963f' : { //Jedi-Devices 7x7m
    group_type: [],
    tracker_type: ['phone'],
    map_order: ['1'],
    maps: {
      '1' : {
        background_src: '7x7grid.png',
        small: {
          width: 1055,
          height: 1055,
          pixels_per_meter: 150.71
        },
        origin: {x: 0, y: 0}
      }
    },
  },
  'd129e001bf5c46f4a5917e9792fd2248' : { //Attic24
    group_type: [],
    tracker_type: ['worker'],
    map_order: ['1'],
    maps: {
      '1' : {
        background_src: 'Attic24.png',
        small: {
          width: 315,
          height: 1250,
          pixels_per_meter: 62.5
        },
        origin: {x: 0, y: 0}
      }
    },
  },
  '18e738e87a7c4181ab3eb5c5df47be98' : { //Belmont-Integration
    group_type: [],
    tracker_type: ['worker'],
    map_order: ["1"],
    maps: {
      "1" : {
        background_src: 'BelmontOffice3.png',
        small: {
          width: 1055,
          height: 592,
          pixels_per_meter: 19.2,
        },
        origin: {x: 0, y: 0},
        gt: {
          'gt1': {x: 41, y: 15, z: 1.524},
          'gt2': {x: 39, y: 16, z: 1.524},
          'gt3': {x: 38, y: 18, z: 1.524},
          'gt4': {x: 39, y: 20, z: 1.524},
          'gt5': {x: 41, y: 21, z: 1.524},
          'gt6': {x: 43, y: 20, z: 1.524},
          'gt7': {x: 44, y: 18, z: 1.524},
          'gt8': {x: 43, y: 16, z: 1.524},
        }
      }
    },
  },
  'f6aa5b0283114de6a3d8933bebc6dc4a' : { //Prd_jpn/Belmont_Trackers_Demo
    group_type: [],
    tracker_type: ['forklift', 'worker'],
    map_order: ["1"],
    maps: {
      "1" : {
        background_src: 'BelmontIndoorOutdoorMap.png',
        small: {
          width: 1055,
          height: 1511,
          pixels_per_meter: 14.95,
        },
        origin: {x: 15.8, y: 0},
        gt: {
          'gt1': {x: 41, y: 15, z: 1.524},
          'gt2': {x: 39, y: 16, z: 1.524},
          'gt3': {x: 38, y: 18, z: 1.524},
          'gt4': {x: 39, y: 20, z: 1.524},
          'gt5': {x: 41, y: 21, z: 1.524},
          'gt6': {x: 43, y: 20, z: 1.524},
          'gt7': {x: 44, y: 18, z: 1.524},
          'gt8': {x: 43, y: 16, z: 1.524},
        }
      }
    },
  },
  '9e5203417d1d40fcb96b9b531cfc531b' : { //Prd_jpn/Belmont_Devices_Demo
    group_type: [],
    tracker_type: ['phone', 'tdoa_tracker', 'worker'],
    map_order: ["1"],
    maps: {
      "1" : {
        background_src: 'ZainarWifiNew3.png',
        small: {
          width: 1055,
          height: 346,
          pixels_per_meter: 32.9,
        },
        origin: {x: 0, y: 1.75},
        gt: {
          'gt1': {x: 5.9475, y: 5.612, z: 1.524},
          'gt2': {x: 9.9125, y: 5.612, z: 1.524},
          'gt3': {x: 13.2675, y: 5.612, z: 1.524},
          'gt4': {x: 16.47, y: 5.612, z: 1.524},
          'gt5': {x: 20.13, y: 21, z: 1.524},
          'gt6': {x: 5.0325, y: 3.05, z: 1.524},
          'gt7': {x: 8.845, y: 1.525, z: 1.524},
          'gt8': {x: 11.59, y: 1.525, z: 1.524},
          'gt9': {x: 14.335, y: 7.1675, z: 1.524},
          'gt10': {x: 15.25, y: 1.525, z: 1.524},
          'gt11': {x: 17.69, y: 1.525, z: 1.524},
          'gt12': {x: 20.923, y: 2.135, z: 1.524},
        }
      }
    }
  },
  '12b344e80f1347e088f16fe679202127' : { //Fukuoka_kajima_kstation
    group_type: [],
    tracker_type: ['forklift', 'worker'],
    map_order: ['1'],
    maps: {
      '1' : {
        background_src: 'Kajima2D.png',
        small: {
          width: 1055,
          height: 508.26,
          pixels_per_meter: 3.95,
        },
        origin: {x: 0, y: 0.1}
      }
    },
  },
  'bb270b27478b435cbadec165809783ed' : { //Kasugabaru Station
    group_type: [],
    tracker_type: ['forklift', 'worker'],
    map_order: ['1'],
    maps: {
      '1' : {
        background_src: 'Kajima2D.png',
        small: {
          width: 1055,
          height: 508.26,
          pixels_per_meter: 3.95,
        },
        origin: {x: 0, y: 0.1}
      }
    },
  },
  '38df7d876e454d10bc89b0b8a32fd438' : { //Koto Pumping Station Phase 1A - 56.80m x 77.75m.
    group_type: [],
    tracker_type: ['forklift', 'worker'],
    map_order: ['1'],
    maps: {
      '1' : {
        background_src: 'KotoPumpFinal.png',
        small: {
          width: 738.5,
          height: 1009.4,
          pixels_per_meter: 13,
        },
        origin: {x:0.6, y:0.65}
      }
    },
  },
  'e0a6a86256cc4281ac180d3fa3cdc4bc' : { //Koto Pumping Station - 56.80m x 77.75m.
    group_type: [],
    tracker_type: ['forklift', 'worker'],
    map_order: ['1'],
    maps: {
      '1' : {
        background_src: 'KotoPumpFinal.png',
        small: {
          width: 738.5,
          height: 1009.4,
          pixels_per_meter: 13,
        },
        origin: {x:0.6, y:0.65}
      }
    },
  },
  '180e0295e41b424bbb920a793b35088e' : {
    group_type: [],
    tracker_type: ['phone'],
    map_order: ["1"],
    maps: {
      "1" : {
        background_src: 'ZainarWifiNew3.png',
        small: {
          width: 1055,
          height: 346,
          pixels_per_meter: 32.9,
        },
        origin: {x: 0, y: 1.75},
        gt: {
          'gt1': {x: 5.9475, y: 5.612, z: 1.524},
          'gt2': {x: 9.9125, y: 5.612, z: 1.524},
          'gt3': {x: 13.2675, y: 5.612, z: 1.524},
          'gt4': {x: 16.47, y: 5.612, z: 1.524},
          'gt5': {x: 20.13, y: 21, z: 1.524},
          'gt6': {x: 5.0325, y: 3.05, z: 1.524},
          'gt7': {x: 8.845, y: 1.525, z: 1.524},
          'gt8': {x: 11.59, y: 1.525, z: 1.524},
          'gt9': {x: 14.335, y: 7.1675, z: 1.524},
          'gt10': {x: 15.25, y: 1.525, z: 1.524},
          'gt11': {x: 17.69, y: 1.525, z: 1.524},
          'gt12': {x: 20.923, y: 2.135, z: 1.524},
        }
      }
    }
  },
  '33de9f1fbab546c4b492b165133cf3c6' : { //春日原駅
    group_type: [],
    tracker_type: ['worker', 'forklift'],
    map_order: ['1'],
    maps: {
      '1' : {
        background_src: 'Belmont-Integration.png',
        small: {
          width: 1055,
          height: 704,
          pixels_per_meter: 70.35
        },
        origin: {x: 0.05, y: 0.05}
      }
    },
  },
  '0e637235124e402b94f46255a862a41a' : { //Belmont-HW-Qualification
    group_type: [],
    tracker_type: ['forklift', 'worker'],
    map_order: ["1"],
    maps: {
      "1" : {
        background_src: 'BelmontIndoorOutdoorMap.png',
        small: {
          width: 1055,
          height: 1511,
          pixels_per_meter: 14.95,
        },
        origin: {x: 15.8, y: 0},
        gt: {
          'gt1': {x: 41, y: 15, z: 1.524},
          'gt2': {x: 39, y: 16, z: 1.524},
          'gt3': {x: 38, y: 18, z: 1.524},
          'gt4': {x: 39, y: 20, z: 1.524},
          'gt5': {x: 41, y: 21, z: 1.524},
          'gt6': {x: 43, y: 20, z: 1.524},
          'gt7': {x: 44, y: 18, z: 1.524},
          'gt8': {x: 43, y: 16, z: 1.524},
        }
      }
    },
  },
  '677f75cdfac4485697128fd6f1698df1' : { //Belmont-5G-Dev
    group_type: [],
    tracker_type: ['worker'],
    map_order: ["1"],
    maps: {
      "1" : {
        background_src: 'BelmontOffice3.png',
        small: {
          width: 1055,
          height: 592,
          pixels_per_meter: 19.2,
        },
        origin: {x: 0, y: 0},
        gt: {
          'gt1': {x: 41, y: 15, z: 1.524},
          'gt2': {x: 39, y: 16, z: 1.524},
          'gt3': {x: 38, y: 18, z: 1.524},
          'gt4': {x: 39, y: 20, z: 1.524},
          'gt5': {x: 41, y: 21, z: 1.524},
          'gt6': {x: 43, y: 20, z: 1.524},
          'gt7': {x: 44, y: 18, z: 1.524},
          'gt8': {x: 43, y: 16, z: 1.524},
        }
      }
    },
  },
  '9c62b64cf30a4ca9bfba79350205d2f3' : {
    group_type: [],
    tracker_type: ['forklift', 'worker'],
    map_order: ["1"],
    maps: {
      "1" : {
        background_src: 'BelmontOffice3.png',
        small: {
          width: 1055,
          height: 592,
          pixels_per_meter: 19.2,
        },
        origin: {x: 0, y: 0},
      }
    },
  },
  'a0536dbd1ce444e7a844b632cb7d1447' : { //Kobe Ice Rink
    group_type: [],
    tracker_type: ['forklift', 'worker'],
    map_order: ['1'],
    maps: {
      '1' : {
        background_src: 'KobeIceRink.png',
        small: {
          width: 508,
          height: 860,
          pixels_per_meter: 11.56,
        },
        origin: {x:0.3, y:0.3}
      }
    },
  },
};

export const TRACKER_CONFIG = { //inactive is disconnected
  'worker': {
              'dead_battery': require("./img/dead_battery.svg"),
              'dead_battery_sel': require("./img/dead_battery_sel.svg"),
              'dead_disconnected_bat': require("./img/dead_disconnected_bat.svg"),
              'dead_disconnected_bat_sel': require("./img/dead_disconnected_bat_sel.svg"),
              'low_battery': require('./img/low_battery.svg'),
              'low_battery_sel': require('./img/low_battery_sel.svg'),
              'tdoa': false,
              'active': require('./img/worker.svg'),
              'active_sel': require('./img/worker_sel.svg'),
              'inactive': require('./img/worker_inactive.svg'),
              'inactive_sel': require('./img/worker_inactive_sel.svg'),
              'idle': require('./img/worker_idle.svg'),
              'idle_sel': require('./img/worker_idle_sel.svg'),
              'select': require('./img/worker_select.png'),
              'unselect': require('./img/worker_unselect.png')
            },
  'forklift': {
               'dead_battery': require("./img/dead_battery.svg"),
               'dead_battery_sel': require("./img/dead_battery_sel.svg"),
               'dead_disconnected_bat': require("./img/dead_disconnected_bat.svg"),
               'dead_disconnected_bat_sel': require("./img/dead_disconnected_bat_sel.svg"),
               'low_battery': require('./img/low_battery.svg'),
               'low_battery_sel': require('./img/low_battery_sel.svg'),
               'tdoa': false,
               'active': require('./img/forklift.svg'),
               'active_sel': require('./img/forklift_sel.svg'),
               'inactive': require('./img/forklift_inactive.svg'),
               'inactive_sel': require('./img/forklift_inactive_sel.svg'),
               'idle': require('./img/forklift_idle.svg'),
               'idle_sel': require('./img/forklift_idle_sel.svg'),
               'select': require('./img/forklift_select.png'),
               'unselect': require('./img/forklift_unselect.png')
             },
  //for Ichikawa groups
  'Floor 1worker': {
                     'dead_battery': require("./img/dead_battery_f1.svg"),
                     'dead_battery_sel': require("./img/dead_battery_f1_sel.svg"),
                     'dead_disconnected_bat': require("./img/dead_disconnected_bat.svg"),
                     'dead_disconnected_bat_sel': require("./img/dead_disconnected_bat_sel.svg"),
                     'low_battery': require('./img/low_battery_f1.svg'),
                     'low_battery_sel': require ('./img/low_battery_sel_f1.svg'),
                     'tdoa': false,
                     'active': require('./img/Floor 1_worker.svg'),
                     'active_sel': require ('./img/Floor 1_worker_sel.svg'),
                     'inactive': require('./img/worker_inactive.svg'),
                     'inactive_sel': require('./img/worker_inactive_sel.svg'),
                     'idle': require('./img/worker_idle.svg'),
                     'idle_sel': require('./img/worker_idle_sel.svg'),
                     'select': require('./img/worker_select.png'),
                     'unselect': require('./img/worker_unselect.png')
                   },
  'Floor 1forklift': {
                       'dead_battery': require("./img/dead_battery_f1.svg"),
                       'dead_battery_sel': require("./img/dead_battery_f1_sel.svg"),
                       'dead_disconnected_bat': require("./img/dead_disconnected_bat.svg"),
                       'dead_disconnected_bat_sel': require("./img/dead_disconnected_bat_sel.svg"),
                       'low_battery': require('./img/low_battery_f1.svg'),
                       'low_battery_sel': require ('./img/low_battery_sel_f1.svg'),
                       'tdoa': false,
                       'active': require('./img/Floor 1_forklift.svg'),
                       'active_sel': require('./img/Floor 1_forklift_sel.svg'),
                       'inactive': require('./img/forklift_inactive.svg'),
                       'inactive_sel': require('./img/forklift_inactive_sel.svg'),
                       'idle': require('./img/forklift_idle.svg'),
                       'idle_sel': require('./img/forklift_idle_sel.svg'),
                       'select': require('./img/forklift_select.png'),
                       'unselect': require('./img/forklift_unselect.png')
                     },
  'Floor 2worker': {
                     'dead_battery': require("./img/dead_battery.svg"),
                     'dead_battery_sel': require("./img/dead_battery_sel.svg"),
                     'dead_disconnected_bat': require("./img/dead_disconnected_bat.svg"),
                     'dead_disconnected_bat_sel': require("./img/dead_disconnected_bat_sel.svg"),
                     'low_battery': require('./img/low_battery.svg'),
                     'low_battery_sel': require('./img/low_battery_sel.svg'),
                     'tdoa': false,
                     'active': require('./img/worker.svg'),
                     'active_sel': require('./img/worker_sel.svg'),
                     'inactive': require('./img/worker_inactive.svg'),
                     'inactive_sel': require('./img/worker_inactive_sel.svg'),
                     'idle': require('./img/worker_idle.svg'),
                     'idle_sel': require('./img/worker_idle_sel.svg'),
                     'select': require('./img/worker_select.png'),
                     'unselect': require('./img/worker_unselect.png')
                   },
  'Floor 2forklift': {
                       'dead_battery': require("./img/dead_battery.svg"),
                       'dead_battery_sel': require("./img/dead_battery_sel.svg"),
                       'dead_disconnected_bat': require("./img/dead_disconnected_bat.svg"),
                       'dead_disconnected_bat_sel': require("./img/dead_disconnected_bat_sel.svg"),
                       'low_battery': require('./img/low_battery.svg'),
                       'low_battery_sel': require('./img/low_battery_sel.svg'),
                       'tdoa': false,
                       'active': require('./img/forklift.svg'),
                       'active_sel': require('./img/forklift_sel.svg'),
                       'inactive': require('./img/forklift_inactive.svg'),
                       'inactive_sel': require('./img/forklift_inactive_sel.svg'),
                       'idle': require('./img/forklift_idle.svg'),
                       'idle_sel': require('./img/forklift_idle_sel.svg'),
                       'select': require('./img/forklift_select.png'),
                       'unselect': require('./img/forklift_unselect.png')
                     },
  //phone does not have disconnected state
  'phone': {
            'dead_battery': require("./img/dead_battery.svg"),
            'dead_battery_sel': require("./img/dead_battery_sel.svg"),
            'dead_disconnected_bat': require("./img/dead_disconnected_bat.svg"),
            'dead_disconnected_bat_sel': require("./img/dead_disconnected_bat_sel.svg"),
            'low_battery': require('./img/phone.svg'),
            'low_battery_sel': require('./img/phone_sel.svg'),
            'tdoa': true,
            'active': require('./img/phone.svg'),
            'active_sel': require('./img/phone_sel.svg'),
            'inactive': require('./img/phone.svg'),
            'inactive_sel': require('./img/phone_sel.svg'),
            'idle': require('./img/phone_idle.svg'),
            'idle_sel': require('./img/phone_idle_sel.svg'),
            'select': require('./img/phone_select.png'),
            'unselect': require('./img/phone_unselect.png')
           },

  'tdoa_tracker': {
                    'dead_battery': require("./img/dead_battery.svg"),
                    'dead_battery_sel': require("./img/dead_battery_sel.svg"),
                    'dead_disconnected_bat': require("./img/dead_disconnected_bat.svg"),
                    'dead_disconnected_bat_sel': require("./img/dead_disconnected_bat_sel.svg"),
                    'low_battery': require('./img/tdoa_tracker.svg'),
                    'low_battery_sel': require('./img/tdoa_tracker_sel.svg'),
                    'tdoa': true,
                    'active': require('./img/tdoa_tracker.svg'),
                    'active_sel': require('./img/tdoa_tracker_sel.svg'),
                    'inactive': require('./img/worker_inactive.svg'),
                    'inactive_sel': require('./img/worker_inactive_sel.svg'),
                    'idle': require('./img/worker_idle.svg'),
                    'idle_sel': require('./img/worker_idle_sel.svg'),
                    'select': require('./img/tdoa_tracker_select.png'),
                    'unselect': require('./img/worker_unselect.png')
                  },
};

export const IMG_CONFIG = {
  'live' : {
    'active': {
      'img': require('./img/live_select.png'),
      'tooltip': 'Live'
    },
    'inactive': {
      'img': require('./img/live_unselect.png'),
      'tooltip': 'Live View',
    }
  },
  'path' : {
    'active': {
      'img': require('./img/path_select.png'),
      'tooltip': 'Paths',
    },
    'inactive': {
      'img': require('./img/path_unselect.png'),
      'tooltip': 'Show Paths',
    }
  },
  'heatmap' : {
    'active': {
      'img': require('./img/heatmap_select.png'),
      'tooltip': 'Heatmap',
    },
    'inactive': {
      'img': require('./img/heatmap_unselect.png'),
      'tooltip': 'Show Heatmap',
    }
  },
  'zone' : {
    'active': {
      'img': require('./img/zone_select.png'),
      'tooltip': 'Hide Zones'
    },
    'inactive': {
      'img': require('./img/zone_unselect.png'),
      'tooltip': 'Show Zones',
    }
  },
  'anchor' : {
    'active': {
      'img': require('./img/anchor_select.png'),
      'tooltip': 'Hide Anchors',
    },
    'inactive': {
      'img': require('./img/anchor_unselect.png'),
      'tooltip': 'Show Anchors',
    }
  },
  'gt' : {
    'active': {
      'img': require('./img/GT_select.png'),
      'tooltip': 'Hide GTs',
    },
    'inactive': {
      'img': require('./img/GT_unselect.png'),
      'tooltip': 'Show GTs',
    }
  },
  'map' : {
    'active': {
      'img': require('./img/map_select.png'),
      'tooltip': 'Map View',
    },
    'inactive': {
      'img': require('./img/map_unselect.png'),
      'tooltip': 'Show Map View',
    }
  },
  'analytics' : {
    'active': {
      'img': require('./img/analytics_select.png'),
      'tooltip': 'Anayltics View',
    },
    'inactive': {
      'img': require('./img/analytics_unselect.png'),
      'tooltip': 'Show Analytics View',
    }
  },
  'forklift' : {
    'active': {
      'tooltip': 'Hide Forklifts',
    },
    'inactive': {
      'tooltip': 'Show Forklifts',
    }
  },
  'worker' : {
    'active': {
      'tooltip': 'Hide Workers'
    },
    'inactive': {
      'tooltip': 'Show Workers',
    }
  },
  'phone' : {
    'active': {
      'tooltip': 'Hide Phones',
    },
    'inactive': {
      'tooltip': 'Show Phones',
    }
  },
  'picker' : {
    'active': {
      'tooltip': 'Hide Pickers',
    },
    'inactive': {
      'tooltip': 'Show Pickers',
    }
  },
  'forkliftdriver' : {
    'active': {
      'tooltip': 'Hide Driver',
    },
    'inactive': {
      'tooltip': 'Show Driver',
    }
  },
  'tdoa_tracker' : {
    'active': {
      'tooltip': 'Hide TDOA Tracker',
    },
    'inactive': {
      'tooltip': 'Show TDOA Tracker',
    }
  },
  'refresh' : {
    'img': require("./img/refresh.png"),
    'tooltip': 'Refresh',
  },
  'unlink' : {
    'img': require("./img/unlink.png"),
    'tooltip': 'Check-in Tracker',
  },
  'inactive' : { //heatmap legend
    'active': {
      'img': require("./img/inactive_select.png"),
      'tooltip': 'Show Total',
    },
    'inactive': {
      'img': require("./img/inactive_unselect.png"),
      'tooltip': 'Show Inactive',
    }
  },
  'active' : {
    'active': {
      'img': require("./img/active_select.png"),
      'tooltip': 'Show Total',
    },
    'inactive': {
      'img': require("./img/active_unselect.png"),
      'tooltip': 'Show Active',
    }
  },
  'upload' : { //wms file upload
    'active': {
      'img': require("./img/upload.png")
    }
  },
  'download': { //wms file download
    'active': {
      'img': require("./img/download.png")
    }
  },
  'toggle_on' : {
    'active': {
      'img' : require("./img/on.png")
    },
    'inactive' : {
      'img' : require("./img/off.png")
    }
  },
  'legend_percent': {
    'active': {
      'img': require("./img/legend_percent_select.png")
    },
    'inactive': {
      'img': require("./img/legend_percent_unselect.png")
    }
  },
  'legend_quantity': {
    'active': {
      'img': require("./img/legend_quantity_select.png")
    },
    'inactive': {
      'img': require("./img/legend_quantity_unselect.png")
    }
  },
  'legend_percentile': {
    'active': {
      'img': require("./img/legend_percentile_select.png")
    },
    'inactive': {
      'img': require("./img/legend_percentile_unselect.png")
    }
  },
  'show_path' : {
    'active': {
      'img' : require("./img/show_path.png")
    },
    'inactive': {
      'img' : require("./img/hide_path.png")
    }
  },
  'All' : {
    'active' : {
      'img' : require('./img/all_active.png')
    },
    'inactive' : {
      'img': require('./img/all_inactive.png')
    }
  },
  '1' : {
    'active' : {
      'img' : require('./img/1_active.png')
    },
    'inactive' : {
      'img': require('./img/1_inactive.png')
    }
  },
  '2' : {
    'active' : {
      'img' : require('./img/2_active.png')
    },
    'inactive' : {
      'img': require('./img/2_inactive.png')
    }
  },
  '3' : {
    'active' : {
      'img' : require('./img/3_active.png')
    },
    'inactive' : {
      'img': require('./img/3_inactive.png')
    }
  },
  '4' : {
    'active' : {
      'img' : require('./img/4_active.png')
    },
    'inactive' : {
      'img': require('./img/4_inactive.png')
    }
  },
  '5' : {
    'active' : {
      'img' : require('./img/5_active.png')
    },
    'inactive' : {
      'img': require('./img/5_inactive.png')
    }
  },
  'checked_out' : {
    'active': {
      'img': require('./img/linked-selected.png'),
      'tooltip': 'Hide Checked Out Trackers',
    },
    'inactive': {
      'img': require('./img/linked-unselected.png'),
      'tooltip': 'Show Checked Out Trackers',
    }
  },
  'checked_in' : {
    'active': {
      'img': require('./img/unlinked-selected.png'),
      'tooltip': 'Hide Checked In Trackers',
    },
    'inactive': {
      'img': require('./img/unlinked-unselected.png'),
      'tooltip': 'Show Checked In Trackers',
    }
  },
};

export const HEADER_CONFIG = {
  'picking_time' : {
    'tooltip' : 'hh:mm:ss'
  },

  'working_hours' : {
    'tooltip' : 'hh:mm:ss'
  },

  'time_per_visit': {
    'tooltip' : 'hh:mm:ss'
  },

  'items_picked': {
    'tooltip' : 'Pieces'
  },

  'locations_visited': {
    'tooltip' : 'Visits'
  },
};
